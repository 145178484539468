<template>
  <div>
    <v-row>
      <v-col cols="12" md="10" offset-md="1">
        <v-card flat class="pa-10" style="font-size: 16px">
          <div class="d-flex px-md-8">
            <v-spacer></v-spacer>
            <h2 class="text-center">
              How to add a custom domain to your Konvey account
            </h2>
            <v-spacer></v-spacer>
            <v-btn
              small
              icon
              class="text-capitalize mt-1"
              @click="$parent.dnsInstructions = false"
            >
              <v-icon>mdi-keyboard-backspace</v-icon>
              Go back
            </v-btn>
          </div>
          <div class="text-left mt-4">
            <p>
              In this article, you will learn how to integrate your root domain
              into konvey.app
            </p>
            <p>You will need:</p>
            <ul>
              <li>a domain registered outside of konvey.app</li>
              <li>a konvey account</li>
            </ul>
            <p>
              Note 1: A domain name can only be used for one website. If the
              root domain name you intend to integrate to konvey is already
              assigned to an external website, this one will no longer be
              available once it is added to konvey.app.
            </p>
            <p>
              Note 2: These guides are intended to connect your root domain
              (example: www.domain.com) with konvey. If you want to use a
              subdomain instead, please click here.
            </p>
            <h3>Step 1: Add your Domain into Konvey.app</h3>
            <p>
              1.1. Click on your "profile picture dowpdown" >> click on "Custom
              Domains >> then click on "Add Domain"
            </p>
            <v-img
              :src="require('../../assets/domain-instructions/1.png')"
            ></v-img>
            <p>
              1.2. Once the first popup appears, put in your domain name with or
              without www in front of the domain name. Example: domain.com or
              www.domain.com
            </p>
            <v-img
              :src="require('../../assets/domain-instructions/2.png')"
            ></v-img>
            <v-img
              :src="require('../../assets/domain-instructions/3.png')"
            ></v-img>
            <p>
              1.3. Once the domain name is added, a second pop-up appears. You
              will see two DNS records that we call CNAMEs. These CNAMEs are the
              DNS that you will add to the DNS zone of your provider to connect
              your domain name
            </p>
            <v-img
              :src="require('../../assets/domain-instructions/4.png')"
            ></v-img>
            <h3>Step 2: Add your CNAME Records</h3>
            <p>
              Sign in to your account that is hosting your domain and create two
              DNS records, then type the CNAME mentioned in the previous pop-up.
            </p>
            <p>
              Using the list below, click on your registrar and follow the
              instructions given on how to create your CNAME record:
            </p>
            <ul>
              <li>GoDaddy</li>
              <li>NameCheap</li>
            </ul>
            <p>
              Note 3: Regarding other hosts, it is necessary to contact their
              support to know the exact location of the settings you need if you
              can't find them.
            </p>
            <p>
              Now, once on your host, you must follow the general instructions
              below:
            </p>
            <p>2.1. Go to your DNS zone to create new "A" record and CNAME.</p>
            <p>
              2.2. You have two records displayed on your konvey account, so you
              will need to create those two records on your host and add them as
              follows:
            </p>
            <v-data-table
              :headers="headers"
              :items="domain_instructions"
              hide-default-footer
            ></v-data-table>
            <p>
              (The CNAME records above are examples; you must add your own
              values).
            </p>
            <p>
              Here is an image of the elements you need to add to your DNS zone:
            </p>
            <v-img
              :src="require('../../assets/domain-instructions/5.png')"
            ></v-img>
            <p>
              2.3 Once the two CNAMEs are added to your host, you can check your
              DNS RECORDS to confirm changes using this website:
            </p>
            <a href="https://dnschecker.org" target="_blank"
              >https://dnschecker.org</a
            >
            <p>
              They should all be verified like the example below. If not, the
              records were not entered correctly. You need to edit your DNS.
            </p>
            <p>
              Note 4: It can be up to 24-48 hours before the DNS records are
              fully propagated.
            </p>
            <p>
              Note 5: Sometimes your provider may not be able to register your
              CNAMEs due to a conflict with another DNS. This usually happens
              when the first CNAME is added.
            </p>
            <p>
              At this time, you need to contact your host to find out the
              importance of these DNS and to give you permission to remove them.
            </p>
          </div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="secondary text-capitalize black--text"
              @click="$parent.dnsInstructions = false"
              rounded
              depressed
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "DNSInstructions",
  data() {
    return {
      headers: [
        { text: "Record Type", value: "record_type" },
        { text: "Host", value: "host" },
        { text: "Value", value: "value" },
      ],
      domain_instructions: [
        {
          record_type: "A",
          host: "@",
          value: "216.24.57.1",
        },
        {
          record_type: "CNAME",
          host: "www",
          value: "domain.com",
        },
      ],
    };
  },
};
</script>

<style scoped></style>
