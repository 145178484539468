<template>
  <div>
    <v-dialog max-width="550" v-model="dialog">
      <v-card>
        <v-card-title class="text-h5 lighten-2">Domain Settings</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-6">
          <v-form lazy-validation ref="settings" class="text-left">
            <div>
              <label class="tagline">
                Tagline
                <v-chip class="secondary darken-4 rounded-chip pointer">
                  ?
                </v-chip>
                <span class="toolip">
                  The descriptive word phrases that are briefly converying what
                  your business is in a nutshell.
                </span>
              </label>
              <v-text-field
                solo
                flat
                dense
                rounded
                background-color="secondary"
                placeholder=""
                type="text"
                v-model="form.tagline"
                class="mt-1"
              ></v-text-field>
            </div>
            <div>
              <v-row no-gutters>
                <v-col cols="7" sm="7" md="7">
                  <div style="text-align: left">
                    <label class="d-flex mb-2">
                      <i class="mdi mdi-image mdi-18px mt-1 mr-1"></i>
                      <span class="mt-1 settings">
                        Favicon Settings
                        <v-chip class="secondary darken-4 rounded-chip pointer">
                          ?
                        </v-chip>
                        <span class="toolip">
                          The icon associated with your particular domain,
                          typically displayed in the address bard of a browser
                          or next to the site's name
                        </span>
                      </span>
                    </label>
                    <img
                      @click="selectFile"
                      :src="upload_img"
                      height="60"
                      width="200"
                      alt=""
                      class="pointer"
                    />
                    <br />
                    <small class="primary--text font-weight-bold">
                      For best fit, use 276 x 279 dimension
                    </small>
                    <v-file-input
                      id="fileUpload"
                      style="position: absolute; opacity: 0"
                      class="mb-n5 mt-n4"
                      prepend-icon=""
                      prepend-inner-icon="mdi-folder-open-outline"
                      label="Upload your icon file"
                      placeholder="Upload your icon file"
                      v-model="form.favicon"
                    ></v-file-input>
                  </div>
                </v-col>
                <v-col cols="5" sm="5" md="5">
                  <div
                    v-if="favicon || favicon_url"
                    class="d-flex current-favicon"
                  >
                    <small class="mt-3 mr-2">Current Favicon</small>
                    <img
                      :src="favicon_url"
                      height="35"
                      width="35"
                      alt="Current Favicon Icon"
                      class="my-0 current-fav rounded-xl"
                    />
                  </div>
                </v-col>
              </v-row>
            </div>
            <div>
              <v-row no-gutters>
                <v-col cols="7" sm="7" md="7">
                  <div>
                    <label class="d-flex mb-2 mt-3">
                      <span class="mt-1 domain-logo">
                        Logo
                        <v-chip class="secondary darken-4 rounded-chip pointer">
                          ?
                        </v-chip>
                        <span class="toolip">
                          Default logo on your recording pages, request links
                          and video playing links.
                        </span>
                      </span>
                    </label>
                    <div class="upload-domain-logo secondary pa-4 rounded-xl">
                      <label>
                        <small style="font-size: 11px">
                          Upload 842 x 292 design for better display
                        </small>
                        <v-btn
                          class="capitalize primary darken-1 ml-2 px-5 mt-2"
                          rounded
                          text
                          small
                          @click="selectDomainLogoFile"
                          :loading="loading"
                        >
                          Choose file
                        </v-btn>
                      </label>
                    </div>
                    <v-file-input
                      id="domainLogoFileUpload"
                      style="position: absolute; opacity: 0"
                      class="mb-n5 mt-n4"
                      prepend-icon=""
                      prepend-inner-icon="mdi-folder-open-outline"
                      label="Upload your icon file"
                      placeholder="Upload your domain logo"
                      v-model="form.domain_logo"
                    ></v-file-input>
                  </div>
                </v-col>
                <v-col cols="5" sm="5" md="5">
                  <div
                    v-if="domain_logo || domain_logo_url"
                    class="d-flex current-favicon"
                  >
                    <small class="mt-3 mr-2">Current Logo</small>
                    <img
                      :src="domain_logo_url"
                      height="60"
                      width="65"
                      alt="Current Domain Logo"
                      class="my-0 current-fav rounded-xl"
                    />
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="secondary text-capitalize black--text"
            @click="openCloseDialog(false)"
            rounded
            depressed
          >
            Close
          </v-btn>
          <v-btn
            class="text-capitalize primary"
            rounded
            depressed
            :loading="loading"
            @click="uploadFavicon"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ResponseHelper from "../../utils/ResponseHelper";
import { mapActions } from "vuex";
export default {
  name: "DomainSettings",
  props: {
    domain_id: String,
    favicon_url: {
      default: null,
      type: String,
    },
    domain_logo_url: {
      default: null,
      type: String,
    },
  },
  data() {
    return {
      dialog: false,
      form: {},
      loading: false,
      toast: new ResponseHelper(),
      favicon: this.favicon_url,
      domain_logo: this.domain_logo_url,
      upload_img: require("@/assets/upload-favicon.png"),
    };
  },
  methods: {
    ...mapActions({
      upload_favicon: "domain/upload_favicon",
    }),
    openCloseDialog(value) {
      this.dialog = value;
    },
    async uploadFavicon() {
      this.loading = true;
      try {
        let formData = new FormData();
        formData.append("favicon", this.form.favicon);
        formData.append("domain_logo", this.form.domain_logo);
        formData.append("domain_id", this.domain_id);
        formData.append("tagline", this.form.tagline);
        const response = await this.upload_favicon(formData);
        this.favicon = response.data.favicon_url;
        this.domain_logo = response.data.domain_logo_url;
        this.openCloseDialog(false);
        this.loading = false;
        this.toast.sendSuccess(response);
        await this.$parent.getDomains();
      } catch (e) {
        this.loading = false;
        this.toast.sendError(e);
      }
    },
    selectFile() {
      document.querySelector("#fileUpload").click();
    },
    selectDomainLogoFile() {
      document.querySelector("#domainLogoFileUpload").click();
    },
  },
};
</script>

<style scoped>
.horizontal-line {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.rounded-chip {
  padding: 4px 5px;
  width: 17px !important;
  height: 17px !important;
  border-radius: 100%;
}
.rounded-chip:hover {
  cursor: help;
  position: relative;
}
.toolip {
  display: none;
}
.settings:hover .toolip {
  border: #c0c0c0 1px dotted;
  padding: 5px 20px 5px 5px;
  display: block;
  z-index: 999999999;
  background: #ffffff;
  left: 0;
  margin: 10px;
  width: 250px;
  position: absolute;
  top: 90;
  text-decoration: none;
  color: #000000;
  font-size: 13px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  text-align: center;
}

.tagline:hover .toolip {
  border: #c0c0c0 1px dotted;
  padding: 5px 20px 5px 5px;
  display: block;
  z-index: 999999999;
  background: #ffffff;
  left: 0;
  margin: 10px;
  width: 250px;
  position: absolute;
  bottom: 340px;
  text-decoration: none;
  color: #000000;
  font-size: 13px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  text-align: center;
}

.domain-logo:hover .toolip {
  border: #c0c0c0 1px dotted;
  padding: 5px 20px 5px 5px;
  display: block;
  z-index: 999999999;
  background: #ffffff;
  left: 0;
  margin: 10px;
  width: 250px;
  position: absolute;
  bottom: 135px;
  text-decoration: none;
  color: #000000;
  font-size: 13px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  text-align: center;
}

.current-fav {
  border-radius: 8% !important;
  box-shadow: 0px 0px 3px #eee;
}
.upload-domain-logo {
  width: 200px !important;
  text-align: center;
  border: 1px dashed blue !important;
}
.current-favicon {
  margin-top: 45px;
}
</style>
