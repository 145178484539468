import { render, staticRenderFns } from "./ShowInstructions.vue?vue&type=template&id=32ad3247&scoped=true"
import script from "./ShowInstructions.vue?vue&type=script&lang=js"
export * from "./ShowInstructions.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32ad3247",
  null
  
)

export default component.exports