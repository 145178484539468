<template>
  <div>
    <v-row class="ml-1" v-if="!create && !instructions">
      <v-btn class="text-capitalize primary" rounded @click="addDomain">
        <v-icon class="white primary--text rounded-xl pa-1 mr-1 ml-n3" small>
          mdi-plus
        </v-icon>
        Add Domain
      </v-btn>
    </v-row>
    <v-row v-if="!create && !instructions">
      <v-col>
        <v-toolbar flat>
          <v-toolbar-title>
            Custom Domain {{ domain_length }} of {{ user.custom_domains }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-title>
            <v-btn class="text-capitalize" depressed @click="reloadPage">
              <v-icon
                class="white primary--text rounded-xl pa-1 mr-1 ml-n3"
                small
                >mdi-cached</v-icon
              >
              Refresh
            </v-btn>
          </v-toolbar-title>
        </v-toolbar>
        <v-data-table
          :headers="headers"
          :items="domains"
          :items-per-page="10"
          loading-text="Loading domains, please wait..."
          :loading="loadingDomains"
        >
          <template v-slot:item.numbers="{ index }">
            <div>{{ index + 1 }}</div>
          </template>
          <template v-slot:item.name="{ item }">
            <div>
              {{ item.name }}
              <v-icon v-if="item.default === 'yes'" color="green">
                mdi-check
              </v-icon>
            </div>
          </template>
          <template v-slot:item.status="{ item }">
            <div>
              {{ item.status }}
              <a href="#" @click="showInstructions(item)">
                Show instructions
              </a>
            </div>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon color="primary" dark v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item link>
                  <v-list-item-title @click="markAsDefault(item)">
                    Mark domain default
                  </v-list-item-title>
                </v-list-item>
                <template v-if="item.name !== 'dashboard.konvey.app'">
                  <v-list-item link>
                    <v-list-item-title @click="showSettings(item)">
                      Settings
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item link>
                    <v-list-item-title @click="removeDomain(item)">
                      Remove
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <add-domain v-if="create"></add-domain>
    <show-instructions
      v-if="instructions"
      :domain_name="selectedDomain"
    ></show-instructions>
    <domain-settings
      ref="domain_settings"
      :domain_id="domain_id"
      :favicon_url="favicon_url"
      :domain_logo_url="domain_logo_url"
    ></domain-settings>
  </div>
</template>

<script>
import AddDomain from "../../components/Domains/AddDomain";
import ShowInstructions from "../../components/Domains/ShowInstructions";
import DomainSettings from "../../components/Domains/DomainSettings";
import { mapActions, mapGetters } from "vuex";
import ResponseHelper from "../../utils/ResponseHelper";
export default {
  name: "CustomDomain",
  components: {
    AddDomain,
    ShowInstructions,
    DomainSettings,
  },
  data() {
    return {
      loadingDomains: false,
      headers: [
        { text: "#", value: "numbers" },
        { text: "Domain Name", value: "name" },
        { text: "Status", value: "status" },
        { text: "Action", value: "actions" },
      ],
      domains: [],
      create: false,
      instructions: false,
      selectedDomain: "",
      toast: new ResponseHelper(),
      domain_id: "",
      favicon_url: "",
      domain_logo_url: "",
      user_initiated: false,
    };
  },
  created() {
    this.$root.$on("domain-added", () => {
      this.getDomains();
    });
    this.getDomains();
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    domain_length() {
      return this.domains.filter((x) => x.name !== "dashboard.konvey.app")
        .length;
    },
  },
  methods: {
    ...mapActions({
      mark_as_default: "domain/mark_as_default",
      get_domains: "domain/get_domains",
      remove_domain: "domain/remove_domain",
    }),
    showInstructions(item) {
      this.selectedDomain = item.name;
      this.instructions = true;
    },
    addDomain() {
      this.create = true;
    },
    showSettings(item) {
      setTimeout(() => {
        this.$refs.domain_settings.openCloseDialog(true);
        this.domain_id = item.id;
        this.favicon_url = item.favicon_url;
        this.domain_logo_url = item.domain_logo_url;
        this.$refs.domain_settings.form.tagline = item.tagline;
      });
    },
    async markAsDefault(item) {
      if (item.name === "dashboard.konvey.app") {
        this.user_initiated = true;
        this.markKonveyAsDefault();
      }
      this.loadingDomains = true;
      try {
        const response = await this.mark_as_default(item);
        this.loadingDomains = false;
        this.toast.sendSuccess(response);
        this.getDomains();
        location.reload();
      } catch (e) {
        this.loadingDomains = false;
        this.toast.sendError(e);
      }
    },
    async getDomains() {
      this.loadingDomains = true;
      try {
        const response = await this.get_domains();
        response.data.unshift({
          id: "1294045965",
          name: "dashboard.konvey.app",
          status: "Ready to use",
          default: "no",
        });
        this.domains = response.data;
        this.loadingDomains = false;
        this.markKonveyAsDefault();
      } catch (e) {
        this.loadingDomains = false;
        console.log(e);
      }
    },
    async removeDomain(item) {
      this.loadingDomains = true;
      try {
        const response = await this.remove_domain(item);
        this.loadingDomains = false;
        this.toast.sendSuccess(response);
        await this.getDomains();
      } catch (e) {
        this.loadingDomains = false;
        this.toast.sendError(e);
      }
    },
    markKonveyAsDefault() {
      let konvey = this.domains.find((x) => x.name === "dashboard.konvey.app");
      if (!this.user_initiated) {
        if (
          !this.domains.find(
            (x) => x.name !== "dashboard.konvey.app" && x.default === "yes"
          )
        ) {
          konvey["default"] = "yes";
          this.$store.commit("domain/SET_DEFAULT", konvey);
        }
      } else {
        this.domains.find((x) => x.default === "yes")["default"] = "no";
        konvey["default"] = "yes";
      }
      this.user_initiated = false;
    },
    reloadPage() {
      location.reload();
    },
  },
};
</script>

<style scoped></style>
