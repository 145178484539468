<template>
  <div>
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="12" md="6">
        <v-card flat class="pa-4">
          <v-toolbar flat>
            <v-toolbar-title class="font-weight-bold">
              DNS configuration for {{ domain_name }}
            </v-toolbar-title>
          </v-toolbar>
          <v-data-table
            :headers="headers"
            :items="domain_instructions"
            :hide-default-footer="true"
          ></v-data-table>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="secondary text-capitalize black--text"
              @click="$parent.instructions = false"
              rounded
              depressed
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "ShowInstructions",
  props: {
    domain_name: String,
  },
  data() {
    return {
      form: {},
      headers: [
        { text: "Record Type", value: "record_type" },
        { text: "Host", value: "host" },
        { text: "Value", value: "value" },
      ],
      domain_instructions: [
        {
          record_type: "A",
          host: "@",
          value: "216.24.57.1",
        },
        {
          record_type: "CNAME",
          host: "www",
          value: this.domain_name,
        },
      ],
    };
  },
};
</script>

<style scoped></style>
