<template>
  <div>
    <v-row v-if="!dnsInstructions">
      <v-spacer></v-spacer>
      <v-col cols="12" md="6">
        <v-card flat class="pa-4">
          <v-toolbar flat>
            <v-toolbar-title class="font-weight-bold">Create</v-toolbar-title>
          </v-toolbar>
          <v-form lazy-validation ref="domain" class="text-left pa-4">
            <label>Domain Name</label>
            <v-text-field
              solo
              flat
              dense
              rounded
              background-color="secondary"
              :rules="[(v) => !!v || 'This field is required']"
              placeholder="Domain name"
              type="text"
              v-model="form.name"
              class="mb-n5"
            >
            </v-text-field>
            <small style="margin-top: -20px">
              <a href="#" @click="showDNSInstructions">
                Read this article to learn how to setup a custom domain
              </a>
            </small>
          </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="secondary text-capitalize black--text"
              @click="$parent.create = false"
              rounded
              depressed
            >
              Close
            </v-btn>
            <v-btn
              class="text-capitalize primary"
              rounded
              depressed
              @click="addDomain()"
              :loading="loading"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <div v-else>
      <d-n-s-instructions></d-n-s-instructions>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import ResponseHelper from "../../utils/ResponseHelper";
import DNSInstructions from "./DNSInstructions";
export default {
  name: "AddDomain",
  components: {
    DNSInstructions,
  },
  data() {
    return {
      form: {},
      loading: false,
      toast: new ResponseHelper(),
      dnsInstructions: false,
    };
  },
  methods: {
    ...mapActions({
      add_domain: "domain/add_domain",
    }),
    async addDomain() {
      this.loading = true;
      try {
        const response = await this.add_domain(this.form);
        this.loading = false;
        this.toast.sendSuccess(response);
        this.$parent.create = false;
        this.$root.$emit("domain-added");
      } catch (e) {
        this.loading = false;
        this.toast.sendError(e);
      }
    },
    showDNSInstructions() {
      this.dnsInstructions = true;
    },
  },
};
</script>

<style scoped></style>
